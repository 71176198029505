document.addEventListener('DOMContentLoaded', function() {
  // Add a click event listener to the document
  document.addEventListener('click', function(event) {
    // Check if the clicked element has the class "list__item"
    if (event.target.classList.contains('list__item')) {
      // Remove the ".current-link" class from any previously active list item
      const currentLink = document.querySelector('.list__item.current-link');
      if (currentLink) {
        currentLink.classList.remove('current-link');
      }

      // Get the id of the clicked list item
      const pageId = event.target.id;

      if (pageId) {
        // Add the ".current-link" class to the clicked list item
        event.target.classList.add('current-link');

        // Construct the URL and navigate to the page (assuming the pages have .html extensions)
        const pageURL = `${pageId}.html`;
        window.location.href = pageURL;
      }
    }
  });
});





